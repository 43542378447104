
/*******************************************************************************************\
* Copyright 2002-2016 (C) Digital Multi-Media Design (DMMD), LLC (http://www.dmmd.net)      *
* This file is part of DMMD's Software Library.                                             *
* The software is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; *
* without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. *
* DMMD can customize or expand this code for your own application.                          *
* Please contact us at contact@dmmd.net or via phone, at 7 0 3 - 4 3 9 - 0 0 6 7 (USA)      *
* or visit us at our website (http://dmmd.net).                                             *
\*******************************************************************************************/

function threesixtycallback() {
  $('.threesixtyicon').addClass('fade-in');
}


jQuery(document).ready(function ($) {
    
        var isTopWindow = false;
        var fullscreenControl = $("#fullscreen");
        var hotspotCanvas = $("#hotspotcanvas");
        var playPauseControl = $("#play");
        var prevControl = $("#prev");
        var nextControl = $("#next");
        var zoomIn = $("#zoomin");
    	var zoomCheck = $("#zoomcheck");
        var zoomContainer = $("#zoomContainer");
        var draggableContainer = $("#draggableContainer");
        var topContainer = $("#topContainer");
        var imageContainer = $("#imageContainer");
        var controllerContainer = $("#controllerContainer");
        var images = imageContainer.find("img");
        var imagesCount = images.length;
        var multiRowArray = new Array(numberOfRows);
        var isClicked = false;
        var currentPositionX;
        var currentPositionY;
        var currentImage = 0;
        var previousImage = 0;
        
        var animation;
        
        var canvas;
        var radius = 15;

        // default initial zoom parameters
        var valueZoom = 1.0;
        var minZoom = 1.0;
        var stepZoom = 0.1;

        var wasPlaying = false;

        var isHotspotImage = false;

        var isZooming = false;
        var isZoomClicked = false;
        var currentZoomXPosition = 0;
        var currentZoomYPosition = 0;
        var zoomBackgroundPosition = "center";

        var zoomDragPosX = 0;
        var zoomDragPosY = 0;

        var isBouncingFinished = false;
        var bounceRotationCount = 0;

        //

        var isAtTop = false;
        var isAtRight = false;
        var isAtBottom = false;
        var isAtLeft = false;

        var zoomedDraggableWidth = 0;
        var zoomedDraggableHeight = 0;

        var mouseDownXPosition = 0;
        var mouseDownYPosition = 0;

        var isFirstDrag = true;

        var hotspotImageWidth = 24;
        var hotspotImageHeight = 24;

        var touchZoomDistanceStart;
        var touchZoomDistanceEnd;

        var zoomTouchDistanceOld = 0;
        var stepPinchZoom = 0.1;

        var currentRow = 0;

        //
        
        if (self == top)
        {
            isTopWindow = true;

            if (deepZoom === 1)
            {
                $('img').prop('src', function ()
                {
                    return this.src; //this.src.replace("img", "imglarge");
                });
            }
        }
        else
        {
            isTopWindow = false;
        }

        var multiRowCount = 0;
        var imageRowCount = 0;

        for (var i=0; i<numberOfRows; i++)
        {
            multiRowArray[i] = new Array(imagesCount/numberOfRows);
            imageRowCount = 0;

            for (var j=multiRowCount; j<(multiRowCount+(imagesCount/numberOfRows)); j++)
            {
                //console.log(j + " >   " + i + " | " + imageRowCount);
                multiRowArray[i][imageRowCount] = images.eq(j);
                multiRowArray[i][imageRowCount].attr("class", "imageInvisible");

                imageRowCount++;
            }

            multiRowCount += imagesCount/numberOfRows;
        }

        images.eq(0).attr("class", "");
        
        images.on("mousedown", function (e)
        {
            e.preventDefault(); // prevent dragging
        });

        var isMobileBrowser = function()
        {
            var mobileBrowser;
            var userAgent = navigator.userAgent;

            // regex literal, for other user agents, append their name in lower case
            var pattern = new RegExp('android|iphone|ipad|ipod|blackberry|iemobile|webos|opera mini');

            if (pattern.test(userAgent.toLowerCase()))
            {
                mobileBrowser = true;
            }
            else
            {
                mobileBrowser = false;
            }

            return mobileBrowser;
        };

        function onOrientationChange()
        {
            if (window.innerWidth > window.innerHeight)
            {
                for (var i=0; i<imagesCount; i++)
                {
                    images.eq(i).css({"width": "auto"});
                    images.eq(i).css({"height": "100%"});
                }
            }
            else
            {
                for (var i=0; i<imagesCount; i++)
                {
                    images.eq(i).css({"width": "100%"});
                    images.eq(i).css({"height": "auto"});
                }
            }
        }

        function leftPad(number, targetLength)
        {
            var output = number + '';

            while (output.length < targetLength)
            {
                output = '0' + output;
            }

            return output;
        }

        if (isMobileBrowser())
        {
            onOrientationChange();
        }

        $(window).on("orientationchange", function()
        {
            if (isMobileBrowser())
            {
                setTimeout(function()
                {
                    onOrientationChange();
                }, 500);
            }
        });

        displayHotspot();

        hotspotCanvas.on("mousedown touchstart", function (e)
        {
            e.preventDefault();

            if (isPlaying)
            {
                wasPlaying = true;
                doPause();
            }
            else
            {
                wasPlaying = false;
            }

            if (e.type === "touchstart")
            {
                var event = e || window.event;
                var touches = event.touches || event.originalEvent.touches;

                if (touches.length === 1)
                {
                    currentPositionX = touches[0].pageX;
                    currentPositionY = touches[0].pageY;
                }
                else if (touches.length === 2)
                {
                    var dx = touches[0].pageX - touches[1].pageX;
                    var dy = touches[0].pageY - touches[1].pageY;

                    touchZoomDistanceEnd = touchZoomDistanceStart = Math.sqrt(dx * dx + dy * dy);
                }
            }
            else
            {
                currentPositionX = e.pageX;
                currentPositionY = e.pageY;
            }

            var offset = $(this).offset();

            var posRelativeX = e.pageX - offset.left;
            var posRelativeY = e.pageY - offset.top;

            var frameCount = hotspotX.length/imagesCount;
            var count = currentImage;

            if ((hotspotX.length !== 0) || (hotspotY.length !== 0))
            {
                if ((hotspotX[count] !== -1) && (hotspotY[count] !== -1))
                {
                    for (var i=currentImage; i<(frameCount*imagesCount); i=i+imagesCount)
                    {
                        var xBounds = (hotspotX[i]) - (hotspotImageWidth/2);
                        var yBounds = (hotspotY[i]) - (hotspotImageHeight/2);

                        if (isTopWindow)
                        {
                            var hotspotXTop = (hotspotX[i] * hotspotCanvas.width()) / normalWidth;
                            var hotspotYTop = (hotspotY[i] * hotspotCanvas.height()) / normalHeight;

                            xBounds = hotspotXTop - (hotspotImageWidth/2);
                            yBounds = hotspotYTop - (hotspotImageHeight/2);
                        }

                        var widthBounds = xBounds + hotspotImageWidth;
                        var heightBounds = yBounds + hotspotImageHeight;

                        if (((posRelativeX >= xBounds) && (posRelativeY >= yBounds)) && ((posRelativeX <= widthBounds) && (posRelativeY <= heightBounds)))
                        {
                            switch (hotspotType[currentImage])
                            {
                            case 0:
                                break;
                            case 1:
                                var wndw = window.open(hotspotAction[currentImage], "_blank");

                                if (wndw)
                                {
                                    wndw.focus();
                                }
                                else
                                {
                                    alert("Please allow popups");
                                }
                                break;
                            case 2:
                                var jsAction = hotspotAction[currentImage];
                                var jsFunctionArr = jsAction.split("#");
                                var jsFunction = jsFunctionArr[0];
                                var jsParams = jsFunctionArr[1];

                                window[jsFunction](jsParams);

                                break;
                            case 3:
                                displayHotspotImage();
                                break;
                            default:
                                break;
                            }
                        }
                    }
                }


            }
            
            isClicked = true;
            return false;
        });
        
        hotspotCanvas.on("mouseup touchend", function ()
        {
            if (isClicked && !isPlaying)
            {
                isClicked = false;

                if (isSingleRotation)
                {
                    doPause();
                }
                else
                {
                    if (wasPlaying)
                    {
                        doPlay();
                    }
                    else
                    {
                        doPause();
                    }
                }
            }
        });

        hotspotCanvas.mouseout(function()
        {
            if (isClicked && !isPlaying)
            {
                isClicked = false;

                if (isSingleRotation)
                {
                    doPause();
                }
                else
                {
                    if (wasPlaying)
                    {
                        doPlay();
                    }
                    else
                    {
                        doPause();
                    }
                }
            }
        });

        hotspotCanvas.on("mousemove touchmove", function (e)
        {
            var offset = $(this).offset();

            var posRelativeX = e.pageX - offset.left;
            var posRelativeY = e.pageY - offset.top;

            var frameCount = hotspotX.length/imagesCount;

            if ((hotspotX.length !== 0) || (hotspotY.length !== 0))
            {
                for (var i=currentImage; i<(frameCount*imagesCount); i=i+imagesCount)
                {
                    if (hotspotType[i] !== 0)
                    {
                        if (hotspotType[currentImage] !== 0)
                        {
                            var xBounds = (hotspotX[i]) - (hotspotImageWidth/2);
                            var yBounds = (hotspotY[i]) - (hotspotImageHeight/2);

                            if (isTopWindow)
                            {
                                var hotspotXTop = (hotspotX[i] * hotspotCanvas.width()) / normalWidth;
                                var hotspotYTop = (hotspotY[i] * hotspotCanvas.height()) / normalHeight;

                                xBounds = hotspotXTop - (hotspotImageWidth/2);
                                yBounds = hotspotYTop - (hotspotImageHeight/2);
                            }

                            var widthBounds = xBounds + hotspotImageWidth;
                            var heightBounds = yBounds + hotspotImageHeight;

                            if (((posRelativeX >= xBounds) && (posRelativeY >= yBounds)) && ((posRelativeX <= widthBounds) && (posRelativeY <= heightBounds)))
                            {
                                hotspotCanvas.css("cursor", "pointer");
                            }
                            else
                            {
                                hotspotCanvas.css("cursor", "ew-resize");
                            }
                        }
                    }
                }
            }

            if (isClicked)
            {
                var xPosition;
                var yPosition;
              
                if (e.type === "touchmove")
                {
                    e.preventDefault();

                    var event = e || window.event;
                    var touches = event.touches || event.originalEvent.touches;

                    if (touches.length === 1)
                    {
                        xPosition = touches[0].pageX;
                        yPosition = touches[0].pageY;
                    }
                    else if (touches.length === 2)
                    {
                        var dx = touches[0].pageX - touches[1].pageX;
                        var dy = touches[0].pageY - touches[1].pageY;

                        touchZoomDistanceEnd = Math.sqrt(dx * dx + dy * dy);

                        var factor = touchZoomDistanceStart / touchZoomDistanceEnd;
                        touchZoomDistanceStart = touchZoomDistanceEnd;

                        if (touchZoomDistanceEnd > zoomTouchDistanceOld)
                        {
                            valueZoom += stepPinchZoom;
                            valueZoom = (valueZoom>maxZoom) ? maxZoom : valueZoom;
                        }
                        else
                        {
                            valueZoom -= stepPinchZoom;
                            valueZoom = (valueZoom<minZoom) ? minZoom : valueZoom;
                        }

                        if (valueZoom <= maxZoom && valueZoom >= minZoom)
                        {
                            document.getElementById("log").innerHTML = valueZoom;
                            doZoom();
                        }

                        if (valueZoom < 1.1)
                        {
                            exitZoom();
                        }

                        zoomTouchDistanceOld = touchZoomDistanceEnd;
                    }
                }
                else
                {
                    xPosition = e.pageX;
                    yPosition = e.pageY;
                }
              
                if (Math.abs(currentPositionX - xPosition) >= sensitivity)
                {
                    if (currentPositionX - xPosition >= sensitivity)
                    {
                        if (isPointerDragNormal)
                        {
                            displayPreviousFrame();
                        }
                        else
                        {
                            displayNextFrame();
                        }
                    }
                    else
                    {
                        if (isPointerDragNormal)
                        {
                            displayNextFrame();
                        }
                        else
                        {
                            displayPreviousFrame();
                        }
                    }
              
                    currentPositionX = xPosition;
                }
            }

            if ( (numberOfRows > 1) && (Math.abs(currentPositionY - yPosition) >= (sensitivityVertical * 10)))
            {
                if (currentPositionY - yPosition >= (sensitivityVertical * 10))
                {
                    if (isPointerDragNormal)
                    {
                        console.log("down");

                        currentRow--;

                        if (currentRow < 0)
                        {
                            currentRow = 0;
                        }

                        displayRowFrame();
                    }
                    else
                    {
                        currentRow++;

                        if (currentRow > (numberOfRows-1))
                        {
                            currentRow = numberOfRows-1;
                        }

                        console.log("up");
                        displayRowFrame();
                    }
                }
                else
                {
                    if (isPointerDragNormal)
                    {
                        currentRow++;

                        if (currentRow > (numberOfRows-1))
                        {
                            currentRow = numberOfRows-1;
                        }

                        console.log("up");
                        displayRowFrame();
                    }
                    else
                    {
                        currentRow--;

                        if (currentRow < 0)
                        {
                            currentRow = 0;
                        }

                        console.log("down");
                        displayRowFrame();
                    }
                }

                currentPositionY = yPosition;
            }
        });
        
        if (document.getElementById("play"))
        {
            document.getElementById("play").addEventListener('click', function(e)
            {
                if (!isPlaying)
                {
                    doPlay();
                }
                else
                {
                    doPause();
                }
            }, false);
        }

        draggableContainer.on("mousedown touchstart", function (e)
        {
            isZoomClicked = true;

            if (e.type === "touchstart")
            {
                var event = e || window.event;
                var touches = event.touches || event.originalEvent.touches;

                if (touches.length === 1)
                {
                    currentZoomXPosition = touches[0].pageX;
                    currentZoomYPosition = touches[0].pageY;
                }
                else if (touches.length === 2)
                {
                    var dx = touches[0].pageX - touches[1].pageX;
                    var dy = touches[0].pageY - touches[1].pageY;

                    touchZoomDistanceEnd = touchZoomDistanceStart = Math.sqrt(dx * dx + dy * dy);
                }
            }
            else
            {
                currentZoomXPosition = e.pageX;
                currentZoomYPosition = e.pageY;
            }
        });

        draggableContainer.on("mouseup touchend", function (e)
        {
            isZoomClicked = false;

            if (e.type === "touchend")
            {
                var event = e || window.event;
                var touches = event.touches || event.originalEvent.touches;

                if (touches.length === 1)
                {
                    currentZoomXPosition = touches[0].pageX;
                    currentZoomYPosition = touches[0].pageY;
                }
                else if (touches.length === 2)
                {
                    touchZoomDistanceStart = touchZoomDistanceEnd = 0;
                }
            }
            else
            {
                currentZoomXPosition = e.pageX;
                currentZoomYPosition = e.pageY;
            }
        });

        draggableContainer.on("mousemove touchmove", function (e)
        {
            if (isZoomClicked && isZooming)
            {
                var zoomXPosition;
                var zoomYPosition;

                if (e.type === "touchmove")
                {
                    var event = e || window.event;
                    var touches = event.touches || event.originalEvent.touches || event.targetTouches;

                    if (touches.length === 1)
                    {
                        zoomXPosition = touches[0].pageX;
                        zoomYPosition = touches[0].pageY;
                    }
                    else if (touches.length === 2)
                    {
                        var dx = touches[0].pageX - touches[1].pageX;
                        var dy = touches[0].pageY - touches[1].pageY;

                        touchZoomDistanceEnd = Math.sqrt(dx * dx + dy * dy);

                        var factor = touchZoomDistanceStart / touchZoomDistanceEnd;
                        touchZoomDistanceStart = touchZoomDistanceEnd;

                        //valueZoom = stepZoom * factor;

                        if (touchZoomDistanceEnd > zoomTouchDistanceOld)
                        {
                            valueZoom += stepPinchZoom;
                            valueZoom = (valueZoom>maxZoom) ? maxZoom : valueZoom;
                        }
                        else
                        {
                            valueZoom -= stepPinchZoom;
                            valueZoom = (valueZoom<minZoom) ? minZoom : valueZoom;
                        }

                        //alert("move: " + valueZoom);
                        //document.getElementById("log").innerHTML = valueZoom;
                        //doZoom();

                        if (valueZoom <= maxZoom && valueZoom >= minZoom)
                        {
                            document.getElementById("log").innerHTML = valueZoom;
                            doZoom();
                        }

                        if (valueZoom < 1.1)
                        {
                            exitZoom();
                        }

                        zoomTouchDistanceOld = touchZoomDistanceEnd;
                    }
                }
                else
                {
                    zoomXPosition = e.pageX;
                    zoomYPosition = e.pageY;
                }

                if (isFirstDrag)
                {
                    isFirstDrag = false;

                    currentZoomXPosition = zoomDragPosX+(zoomedDraggableWidth/2);
                    currentZoomYPosition = zoomDragPosY+(zoomedDraggableHeight/2);
                }

                var currentZoomXDifference = currentZoomXPosition - zoomXPosition;
                var currentZoomYDifference = currentZoomYPosition - zoomYPosition;

                currentZoomXPosition = zoomXPosition;
                currentZoomYPosition = zoomYPosition;

                zoomDragPosX -= currentZoomXDifference;
                zoomDragPosY -= currentZoomYDifference;

                if (zoomDragPosX > 0)
                {
                    zoomDragPosX = 0;
                }

                if (zoomDragPosY > 0)
                {
                    zoomDragPosY = 0;
                }

                var draggableContainerWidth = draggableContainer.width();
                var draggableContainerHeight = draggableContainer.height();

                if (zoomDragPosX < (draggableContainerWidth - zoomedDraggableWidth))
                {
                    zoomDragPosX = draggableContainerWidth - zoomedDraggableWidth;
                }

                if (zoomDragPosY < (draggableContainerHeight - zoomedDraggableHeight))
                {
                    zoomDragPosY = draggableContainerHeight - zoomedDraggableHeight;
                }


                if (zoomDragPosX <= 0)
                {
                isAtLeft = true;
                console.log("left");
                }
                else
                {
                isAtLeft = false;
                }

                if (zoomDragPosY <= 0)
                {
                isAtTop = true;
                console.log("top");
                }
                else
                {
                isAtTop = false;
                }

                //

                if (zoomDragPosX >= (draggableContainerWidth - zoomedDraggableWidth))
                {
                isAtRight = true;
                console.log("right");
                }
                else
                {
                isAtRight = false;
                }

                if (zoomDragPosY >= (draggableContainerHeight - zoomedDraggableHeight))
                {
                isAtBottom = true;
                console.log("bottom");
                }
                else
                {
                isAtBottom = false;
                }

                /*if (!isAtTop && !isAtLeft && !isAtRight) // top left
                {
                console.log("0");
                draggableContainer.css("background-position", zoomDragPosX + "px " + zoomDragPosY + "px");
                }
                else if (isAtTop && !isAtLeft && !isAtRight)
                {
                console.log("1");
                draggableContainer.css("background-position", zoomDragPosX + "px " + 0 + "px");
                }
                else if (isAtLeft && !isAtTop && !isAtRight)
                {
                console.log("2");
                draggableContainer.css("background-position", 0 + "px " + zoomDragPosY + "px");
                }
                else if (isAtLeft && isAtTop && !isAtRight)
                {
                console.log("3");
                draggableContainer.css("background-position", 0 + "px " + 0 + "px");
                }
                else if (!isAtTop && isAtRight && !isAtLeft) // top right
                {
                console.log("4");
                draggableContainer.css("background-position", (draggableContainerWidth - zoomedDraggableWidth) + "px " + zoomDragPosY + "px");
                }
                else if (isAtTop && isAtRight && !isAtLeft)
                {
                console.log("5");
                draggableContainer.css("background-position", (draggableContainerWidth - zoomedDraggableWidth) + "px " + 0 + "px");
                }*/

                var xDragBottomPos = zoomDragPosX + zoomedDraggableWidth;
                var yDragBottomPos = zoomDragPosY + zoomedDraggableHeight;


                console.log("["+draggableContainerWidth);
                console.log("]"+draggableContainerHeight);

                console.log(xDragBottomPos);
                console.log(yDragBottomPos);

                console.log(zoomDragPosX);
                console.log(zoomDragPosY);



                if (zoomDragPosX <= 0)
                    console.log("#0");

                if (zoomDragPosY <= 0)
                    console.log("#1");

                if (xDragBottomPos >= draggableContainerWidth)
                    console.log("#2");

                if (yDragBottomPos >= draggableContainerHeight)
                    console.log("#3");

                if ((zoomDragPosX <= 0) && (zoomDragPosY <= 0) && (xDragBottomPos >= draggableContainerWidth) && (yDragBottomPos >= draggableContainerHeight))
                {
                    draggableContainer.css("background-position", zoomDragPosX + "px " + zoomDragPosY + "px");
                }
                else
                {
                    console.log("stop");
                }

            }
        });

        if (document.getElementById("prev"))
        {
            document.getElementById("prev").addEventListener('click', function(e)
            {
                displayPreviousFrame();

                if (isPlaying)
                {
                    doPause();
                }

            }, false);
        }

        if (document.getElementById("next"))
        {
            document.getElementById("next").addEventListener('click', function(e)
            {
                displayNextFrame();

                if (isPlaying)
                {
                    doPause();
                }

            }, false);
        }

        if (document.getElementById("zoomin"))
        {
            document.getElementById("zoomin").addEventListener('click', function(e)
            {
                if (isPlaying)
                {
                    doPause();
                }

                if (!isZooming)
                {
                    zoomIn.removeClass("zoomin");
                    zoomIn.addClass("zoomout");

                    valueZoom = maxZoom;
                    doZoom();
                }
                else
                {
                    zoomIn.removeClass("zoomout");
                    zoomIn.addClass("zoomin");

                    valueZoom = minZoom;
                    exitZoom();
                }
            }, false);
        }

        if (document.getElementById("fullscreen"))
        {
            document.getElementById("fullscreen").addEventListener('click', function(e)
            {
                // full-screen available?
                if (document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled)
                {
                    // image container
                    var i = document.getElementById("topContainer");

                    // in full-screen?
                    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement)
                    {
                        // exit full-screen
                        if (document.exitFullscreen)
                        {
                            document.exitFullscreen();
                        }
                        else if (document.webkitExitFullscreen)
                        {
                            document.webkitExitFullscreen();
                        }
                        else if (document.mozCancelFullScreen)
                        {
                            document.mozCancelFullScreen();
                        }
                        else if (document.msExitFullscreen)
                        {
                            document.msExitFullscreen();
                        }
                    }
                    else
                    {
                        // go full-screen
                        if (i.requestFullscreen)
                        {
                            this.requestFullscreen();
                        }
                        else if (i.webkitRequestFullscreen)
                        {
                            i.webkitRequestFullscreen();
                        }
                        else if (i.mozRequestFullScreen)
                        {
                            i.mozRequestFullScreen();
                        }
                        else if (i.msRequestFullscreen)
                        {
                            i.msRequestFullscreen();
                        }
                    }
                }

            }, false);
        }

        //Firefox
        topContainer.on("DOMMouseScroll", function(e)
        {
            if (mouseZoom)
            {
                isFirstDrag = true;

                if (e.originalEvent.detail > 0)
                {
                    //scroll down
                    valueZoom += stepZoom;
                    valueZoom = (valueZoom>maxZoom) ? maxZoom : valueZoom;
                }
                else
                {
                    //scroll up
                    valueZoom -= stepZoom;
                    valueZoom = (valueZoom<minZoom) ? minZoom : valueZoom;
                }

                if ((valueZoom >= minZoom) && (valueZoom <= maxZoom))
                {
                    //console.log(valueZoom);
                    //zoomSlider.val(valueZoom);
                    doZoom();
                }

                if(valueZoom < 1.1)
                {
                    exitZoom();
                }
            }

            //allow page to scroll
            return true;
        });

        //IE, Opera, Safari
        topContainer.on("mousewheel", function(e)
        {
            if (mouseZoom)
            {
                isFirstDrag = true;

                if (e.originalEvent.wheelDelta < 0)
                {
                    //scroll down
                    valueZoom += stepZoom;
                    valueZoom = (valueZoom>maxZoom) ? maxZoom : valueZoom;
                }
                else
                {
                    //scroll up
                    valueZoom -= stepZoom;
                    valueZoom = (valueZoom<minZoom) ? minZoom : valueZoom;
                }

                if ((valueZoom >= minZoom) && (valueZoom <= maxZoom))
                {
                    //console.log(valueZoom);
                    //zoomSlider.val(valueZoom);
                    doZoom();
                }

                if(valueZoom < 1.1)
                {
                    exitZoom();
                }
            }

            //allow page to scroll
            return true;
        });

        var doZoom = function()
        {
            isZooming = true;

            zoomIn.removeClass("zoomin");
            zoomIn.addClass("zoomout");

            doPause();

            valueZoom = Math.round(valueZoom*10)/10;

            var imageExtension = images.eq(currentImage).attr("src").split(".").pop();
            var multiRowFileName = (leftPad((currentRow+1), 2)) + "-" + (leftPad((currentImage+1), 2)) + "." + imageExtension;

            if (deepZoom === 1)
            {
                if (isTopWindow)
                {
                    //var largeImageSrc = images.eq(currentImage).attr("src");
                    //var largeImageLastSeparator = largeImageSrc.lastIndexOf("/");
                    //var largeImageFilename = largeImageSrc.substring(largeImageLastSeparator+1);

                    //console.log(largeImageFilename);
                    draggableContainer.css("background-image", "url(imglarge/" + multiRowFileName + ")");
                }
                else
                {
                    draggableContainer.css("background-image", "url(imglarge/" + multiRowFileName + ")");
                }
            }
            else
            {
                draggableContainer.css("background-image", "url(img/" + multiRowFileName + ")");
            }

            draggableContainer.css("background-repeat", "no-repeat");
            draggableContainer.css("background-position", zoomBackgroundPosition);
            //draggableContainer.draggable();
            draggableContainer.css("display", "block");
            zoomContainer.css("display", "block");
            imageContainer.css("display", "none");
            hotspotCanvas.css("visibility", "hidden");
            playPauseControl.css("visibility", "hidden");
            prevControl.css("visibility", "hidden");
            nextControl.css("visibility", "hidden");

            var tempWidth = largeWidth/normalWidth;
            var tempHeight = largeHeight/normalHeight;

            if (isTopWindow)
            {
                zoomedDraggableWidth = valueZoom*($(document).height()*largeWidth/largeHeight);
                zoomedDraggableHeight = valueZoom*($(document).height());
                draggableContainer.css("background-size", zoomedDraggableWidth+"px "+zoomedDraggableHeight+"px");
            }
            else
            {
                zoomedDraggableWidth = valueZoom*($(document).height()*normalWidth/normalHeight);
                zoomedDraggableHeight = valueZoom*($(document).height());
                draggableContainer.css("background-size", zoomedDraggableWidth+"px "+zoomedDraggableHeight+"px");
            }

            zoomDragPosX = ($(document).width() - zoomedDraggableWidth/maxZoom/2);
            zoomDragPosY = ($(document).height() - zoomedDraggableHeight/maxZoom/2);

            //zoomBackgroundPosition = "background-position: " + zoomDragPosX + "px " + zoomDragPosY + "px";

            hotspotCanvas.css("cursor", "move");

            /*if ((mouseDownXPosition === 0) && (mouseDownYPosition === 0))
            {
                // need to change these values
                zoomDragPosX = mouseDownXPosition;
                zoomDragPosY = mouseDownYPosition;

                console.log("z: " + mouseDownXPosition);
            }*/
        };

        var exitZoom = function()
        {
            isZooming = false;

            zoomIn.removeClass("zoomout");
            zoomIn.addClass("zoomin");

            draggableContainer.css("background-image", "none");
            draggableContainer.css("display", "none");
            imageContainer.css("display", "block");
            hotspotCanvas.css("visibility", "visible");
            playPauseControl.css("visibility", "visible");
            prevControl.css("visibility", "visible");
            nextControl.css("visibility", "visible");
            zoomContainer.css("display", "none");

            hotspotCanvas.css("cursor", "ew-resize");

            isFirstDrag = true;
        };

        var displayHotspotImage = function()
        {
            draggableContainer.css("background-image", "url(hotspotimg/"+hotspotAction[currentImage]+")");
            draggableContainer.css("background-repeat", "no-repeat");
            draggableContainer.css("background-position", "center");
            draggableContainer.css("display", "block");
            zoomContainer.css("display", "block");

            imageContainer.css("display", "none");
            hotspotCanvas.css("visibility", "hidden");
            controllerContainer.css("display", "none");

            zoomedDraggableWidth = topContainer.width();
            zoomedDraggableHeight = topContainer.height();

            draggableContainer.css("background-size", zoomedDraggableWidth+"px "+zoomedDraggableHeight+"px");

            isHotspotImage = true;

            draggableContainer.css("cursor", "pointer");
        };

        var hideHotspotImage = function()
        {
            draggableContainer.css("background-image", "none");
            draggableContainer.css("display", "none");
            zoomContainer.css("display", "none");
            imageContainer.css("display", "block");
            hotspotCanvas.css("visibility", "visible");
            controllerContainer.css("display", "block");

            isHotspotImage = false;

            draggableContainer.css("cursor", "move");
        };

        zoomContainer.on("mousedown touchstart", function (e)
        {
            if (isHotspotImage)
            {
                hideHotspotImage();
            }
        });
        
        var displayPreviousFrame = function()
        {
            if (isBouncing && isSingleRotation && (bounceRotationCount === 2) && !isBouncingFinished)
            {
                console.log("finished");
                isBouncingFinished = true;
                //clearInterval(animation);

                doPause();

                return;
            }

        	currentImage++;
            
            if (currentImage >= (imagesCount/numberOfRows))
            {
                if (isBouncing && !isPlaying)
                {
                    currentImage = (imagesCount/numberOfRows)-1;
                }
                else
                {
                    currentImage = 0;
                }
            }

            if ((currentImage == ((imagesCount/numberOfRows)-1)) && isSingleRotation && !isBouncing)
            {
                playPauseControl.attr("class", "play");
                isPlaying = false;

                clearInterval(animation);
            }

            if ((currentImage == ((imagesCount/numberOfRows)-1)) && isBouncing && !isBouncingFinished)
            {
                if (isSingleRotation)
                {
                    bounceRotationCount++;
                }

                if (isPlaying)
                {
                    clearInterval(animation);
                    animation = setInterval(displayNextFrame, playSpeed);
                }
            }

            for (var i=1; i<imagesCount; i++)
            {
                images.eq(i).attr("class", "imageInvisible");
            }
            
            multiRowArray[currentRow][previousImage].addClass("imageInvisible");
            multiRowArray[currentRow][currentImage].removeClass("imageInvisible");

            //images.eq(previousImage).addClass("imageInvisible");
            //images.eq(currentImage).removeClass("imageInvisible");
            
            previousImage = currentImage;
            displayHotspot();
            //document.title = currentImage;
        };
        
        var displayNextFrame = function()
        {
            if (isBouncing && isSingleRotation && (bounceRotationCount === 2) && !isBouncingFinished)
            {
                console.log("finished");
                isBouncingFinished = true;
                //clearInterval(animation);

                doPause();

                return;
            }

        	currentImage--;
            
            if (currentImage < 0)
            {
                if (isBouncing && !isPlaying)
                {
                    currentImage = 0;
                }
                else
                {
                    currentImage = (imagesCount/numberOfRows)-1;
                }
            }
            
            if ((currentImage == 0) && isSingleRotation && !isBouncing)
            {
            	playPauseControl.attr("class", "play");
        		isPlaying = false;
        		
        		clearInterval(animation);

                // Fluidic code added for callback
                threesixtycallback();
            }

            if ((currentImage == 0) && isBouncing && !isBouncingFinished)
            {
                if (isSingleRotation)
                {
                    bounceRotationCount++;
                }

                if (isPlaying)
                {
                    clearInterval(animation);
                    animation = setInterval(displayPreviousFrame, playSpeed);
                }
            }

            for (var i=1; i<imagesCount; i++)
            {
                images.eq(i).attr("class", "imageInvisible");
            }

            multiRowArray[currentRow][previousImage].addClass("imageInvisible");
            multiRowArray[currentRow][currentImage].removeClass("imageInvisible");
            
            //images.eq(previousImage).addClass("imageInvisible");
            //images.eq(currentImage).removeClass("imageInvisible");
            
            previousImage = currentImage;
            displayHotspot();
            //document.title = currentImage;
        };
        
        var displayRowFrame = function()
        {
            /*if (isBouncing && isSingleRotation && bounceRotationCount === 2 && !isBouncingFinished)
            {
                console.log("finished");
                isBouncingFinished = true;
                //clearInterval(animation);

                doPause();

                return;
            }*/
            //console.log(currentImage);

            for (var i=0; i<imagesCount; i++)
            {
                images.eq(i).attr("class", "imageInvisible");
            }

            //multiRowArray[currentRow][previousImage].addClass("imageInvisible");
            multiRowArray[currentRow][currentImage].removeClass("imageInvisible");

            //currentImage = (imagesCount/numberOfRows)-1;

            console.log("row " + currentRow);
            console.log("column " + currentImage);
            console.log(multiRowArray[currentRow][currentImage]);

            //images.eq(previousImage).addClass("imageInvisible");
            //images.eq(currentImage).removeClass("imageInvisible");

            //displayHotspot();
            //document.title = currentImage;
        };

        function displayHotspot()
        {
            // get computed style for image
            //var imgContainer = document.getElementById("hotspotcanvas");

            //imgContainer.width = normalWidth;
            //imgContainer.height = normalHeight;

            //var computedStyle = getComputedStyle(imgContainer);

            // these will return dimensions in *pixel* regardless of what
            // you originally specified for image:
            //var width = parseInt(computedStyle.getPropertyValue("width"), 10);
            //var height = parseInt(computedStyle.getPropertyValue("height"), 10);

            // now use this as width and height for your canvas element:
            canvas = document.getElementById("hotspotcanvas");

            if (!isTopWindow)
            {
                canvas.width = normalWidth;
                canvas.height = normalHeight;
            }
            else
            {
                imageContainer.width = topContainer.parent().width();
                imageContainer.height = topContainer.parent().height();

                // replace this with the size of the parent
                canvas.width = largeWidth;
                canvas.height = largeHeight;
            }
            
            var context = canvas.getContext("2d");
            context.clearRect(0, 0, canvas.width, canvas.height);

            var frameCount = hotspotX.length/imagesCount;
            var count = currentImage;

            if ((hotspotX.length !== 0) || (hotspotX.length !== 0))
            {
                if ((hotspotX[count] !== -1) && (hotspotY[count] !== -1))
                {
                    for (var i=currentImage; i<(frameCount*imagesCount); i=i+imagesCount)
                    {
                        //console.log(i);
                        var x = hotspotX[i];
                        var y = hotspotY[i];

                        var img = new Image();
                        img.src = hotspotImage[i];

                        if (isTopWindow)
                        {
                            x = (x * hotspotCanvas.width()) / normalWidth;
                            y = (y * hotspotCanvas.height()) / normalHeight;
                        }

                        context.drawImage(img, x-(hotspotImageWidth/2), y-(hotspotImageHeight/2), hotspotImageWidth, hotspotImageHeight);
                    }
                }
            }

        }

        var doPlay = function()
        {
            playPauseControl.attr("class", "pause");
            isPlaying = true;
            playCount++;

            if (isRotationDirectionNormal)
            {
                animation = setInterval(displayNextFrame, playSpeed);
            }
            else
            {
                animation = setInterval(displayPreviousFrame, playSpeed);
            }
        }

        var doPause = function()
        {
            playPauseControl.attr("class", "play");
            isPlaying = false;

            if (isBouncing)
            {
                bounceRotationCount = 0;
                isBouncingFinished = false;
            }

            clearInterval(animation);
        }
        
        if (isPlaying)
    	{
            clearInterval(animation);
            doPlay();
    	}
      

       /*
    * Little bit of jQuery to spin the 360 image when it comes into view
    */
    $.fn.isInViewport = function() {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();

      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();

      return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    $(window).on('resize scroll', function() {
      $('.threesixtyimage').each(function() {
        if ($(this).isInViewport() && !isPlaying && !playCount) {
            clearInterval(animation);
            doPlay();
        }
      });
    });  
});
