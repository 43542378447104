function isIosDevice()
{
    var iOSDevice;
    var userAgent = navigator.userAgent;
    var pattern = new RegExp('iphone|ipad|ipod');
    if (pattern.test(userAgent.toLowerCase()))
    {
        iOSDevice = true;
    }
    else
    {
        iOSDevice = false;
    }
    return iOSDevice;
}
function isMsEdge()
{
    var msEdge;
    var userAgent = navigator.userAgent;
    var pattern = new RegExp('edge');
    if (pattern.test(userAgent.toLowerCase()))
    {
        msEdge = true;
    }
    else
    {
        msEdge = false;
    }
    return msEdge;
}
if (isIosDevice() || isMsEdge())
{
    //document.getElementById("fullscreen").style.visibility = "hidden";
}